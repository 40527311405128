<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>团课列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="门店名称">
                                <el-input v-model="search.store_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="上下架状态">
                                <el-select v-model="search.publish_state" placeholder="请选择状态" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="上架" :value=1></el-option>
                                    <el-option label="下架" :value=0></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="审核状态">
                                <el-select v-model="search.examine_status" placeholder="请选择状态" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="审核中" :value="0"></el-option>
                                    <el-option label="审核通过" :value="1"></el-option>
                                    <el-option label="审核失败" :value="2"></el-option>
                                    <el-option label="违规下架" :value="3"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="上课状态">
                                <el-select v-model="search.lesson_state" placeholder="请选择状态" style="width: 100%">
                                    <el-option label="全部" :value=0></el-option>
                                    <el-option label="未开始" :value=1></el-option>
                                    <el-option label="进行中" :value=2></el-option>
                                    <el-option label="已下课" :value=3></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col :span="6">
                            <el-form-item label="课程标题">
                                <el-input v-model="search.title"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="课程名称">
                                <el-input v-model="search.lesson_name"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="6">
                            <el-form-item label="教练昵称">
                                <el-input v-model="search.user_nick"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="教练姓名">
                                <el-input v-model="search.user_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="时间">
                                <el-select v-model="search.time_type" style="width: 30%">
                                    <el-option label="上课时间" value="start_time"></el-option>
                                    <el-option label="发布时间" value="publish_time"></el-option>
                                    <el-option label="创建时间" value="create_time"></el-option>
                                    <el-option label="更新时间" value="update_time"></el-option>
                                </el-select>
                                <el-date-picker style="width: 70%" v-model="searchtime" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>


                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                            @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                            @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button v-if="is_auth('lessonschedule.group.issave')" icon="el-icon-plus" size="medium"
                            type="primary" @click="tosee()">添加
                        </el-button>
                        <el-button v-if="is_auth('lessonschedule.group.lessonschedulecopy')"
                            icon="el-icon-c-scale-to-original" size="medium" type="primary"
                            @click="grouplessoncopyPage = !grouplessoncopyPage">复制周课表
                        </el-button>
                        <el-button v-if="is_auth('lessonschedule.group.setpublish')" icon="el-icon-sort" size="medium"
                            type="primary" @click="setPublishPage = !setPublishPage">批量上下架
                        </el-button>
                        <el-button icon="el-icon-download" disabled size="medium" type="primary">导出
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
                <el-table-column prop="company_name" label="所属企业" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="store_name" label="门店" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>【{{ scope.row.store_company_name }}】{{ scope.row.store_name }}—{{
                            scope.row.classroom_name
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="课程标题" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{ scope.row.title }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="user_nick" label="教练昵称" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div v-if="!is_empty(scope.row.user_nick)">
                            <el-avatar shape="square" :size="22" :src="scope.row.avatar_url"></el-avatar>
                            <span>{{ scope.row.user_nick }}</span>
                        </div>
                        <div v-else>
                            <span>未设置教练</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="lesson_duration" label="上课时间" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>（{{ scope.row.week }}）{{ scope.row.lesson_time[0] }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="quota" label="人数上限">
                    <template slot-scope="scope">
                        <span>{{ scope.row.quota }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="bookings_sum" label="预约人数">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.bookings_sum > 0 && is_auth('order.group.getlist')" type="primary"
                            @click="openOrderlist(scope.row)">
                            {{ scope.row.bookings_sum }}
                        </el-link>
                        <span v-else>{{ scope.row.bookings_sum }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="bookings_min" label="开课人数">
                    <template slot-scope="scope">
                        <span>{{ scope.row.bookings_min }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="is_queue_up" label="允许等候" width="90">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.is_queue_up === 1">是</el-tag>
                        <el-tag size="mini" v-if="scope.row.is_queue_up === 0" type="warning">否</el-tag>
                        <span v-if="scope.row.is_queue_up === 1">（{{ scope.row.queue_up_sum }}）</span>
                    </template>
                </el-table-column>
                <el-table-column prop="is_queue_up" label="允许退款" width="90">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.is_refund === 1">是</el-tag>
                        <el-tag size="mini" v-if="scope.row.is_refund === 0" type="warning">否</el-tag>
                        <span v-if="scope.row.is_refund === 1">（{{ scope.row.refund_sum }}）</span>
                    </template>
                </el-table-column>
                <el-table-column prop="lesson_state" label="上课状态">
                    <template slot-scope="scope">
                        <template v-if="scope.row.canceled_at !== 0">
                            <el-tag size="mini" type="info">已取消</el-tag>
                        </template>
                        <template v-else>
                            <el-tag size="mini" v-if="scope.row.lesson_state === 1">未开始</el-tag>
                            <el-tag size="mini" v-if="scope.row.lesson_state === 2" type="warning">进行中</el-tag>
                            <el-tag size="mini" v-if="scope.row.lesson_state === 3" type="success">已下课</el-tag>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="publish_state" label="上下架状态" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.publish_state === 0" type="warning">下架</el-tag>
                        <el-tag size="mini" v-if="scope.row.publish_state === 1" type="success">上架</el-tag>
                        <span v-if="scope.row.publish_state === 1">&nbsp;{{ scope.row.publish_time }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="examine_status" label="审核状态" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.examine_status === 0">审核中</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_status === 1" type="success">审核通过</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_status === 2" type="danger">审核失败</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_status === 3" type="danger">违规下架</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="lesson_name" label="课程名称" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{ scope.row.lesson_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="价格">
                    <template slot-scope="scope">
                        <span v-if="scope.row.originally_price > 0" class="text-decoration-price">￥{{
                            scope.row.originally_price }}</span>
                        <span>￥{{ scope.row.price }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="lesson_duration" label="课程时长(单节)">
                    <template slot-scope="scope">
                        <span>{{ scope.row.lesson_duration }}分钟</span>
                    </template>
                </el-table-column>
                <el-table-column prop="start_time" label="开始时间" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="end_time" label="结束时间" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="update_time" label="更新时间" width="120" show-overflow-tooltip>
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="480">
                    <template slot-scope="scope">
                        <el-button v-if="is_auth('lessonschedule.group.getinfo')"
                            @click="tosee(scope.row.good_uuid, 'watch')" size="mini">查看
                        </el-button>
                        <el-button v-if="is_auth('lessonschedule.group.issave')" @click="tosee(scope.row.good_uuid, 'edit')"
                            size="mini">编辑
                        </el-button>
                        <el-button v-if="is_auth('lessonschedule.group.cancel')"
                            :disabled="scope.row.lesson_state === 3 || scope.row.canceled_at !== 0"
                            @click="cancel(scope.row)" type="danger" size="mini">取消课程
                        </el-button>
                        <el-button v-if="is_auth('lessonschedule.group.upapply') && scope.row.publish_state === 0 &&
                            scope.row.examine_status === 1 && scope.row.canceled_at === 0"
                            @click="operation_tip(scope.row.good_uuid, scope.row.title, 'upapply')" type="success"
                            size="mini">上架发布
                        </el-button>
                        <el-button
                            v-if="is_auth('lessonschedule.group.withdraw') && scope.row.publish_state === 1 && scope.row.canceled_at === 0"
                            @click="operation_tip(scope.row.good_uuid, scope.row.title, 'withdraw')" type="warning"
                            :disabled="scope.row.lesson_state === 3" size="mini">取消发布
                        </el-button>
                        <el-button v-if="is_auth('lessonschedule.group.isdels')"
                            @click="operation_tip(scope.row.good_uuid, scope.row.title, 'isdels')" type="danger"
                            :disabled="scope.row.is_dels > 0" size="mini">删除
                        </el-button>
                        <el-button @click="operation_tip(scope.row.examine_reason, scope.row.title, 'watch')" type="danger"
                            :disabled="scope.row.examine_status !== 3 && scope.row.examine_status !== 2" size="mini">查看原因
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
                layout="prev, pager, next, total" :current-page.sync="page" :total="count">
            </el-pagination>
            <!--批量上下架-->
            <el-dialog v-if="1" title="批量上下架" :visible.sync="setPublishPage" width="50%" :close-on-press-escape="false"
                :close-on-click-modal="false">
                <Setpublish v-on:getlist="() => {
                    this.setPublishPage = false
                    this.getlist()
                }" />
            </el-dialog>
            <!--复制周课表-->
            <el-dialog v-if="is_auth('lessonschedule.group.lessonschedulecopy')" title="复制周课表"
                :visible.sync="grouplessoncopyPage" width="50%" :close-on-press-escape="false"
                :close-on-click-modal="false">
                <Grouplessoncopy v-on:getlist="() => {
                    this.grouplessoncopyPage = false
                    this.getlist()
                }" />
            </el-dialog>

            <!--预约人数-->
            <el-dialog v-if="is_auth('store.coach.getcoach')" title="团课订单" :visible.sync="OrderlistPage" width="80%"
                :close-on-press-escape="false" :close-on-click-modal="false">
                <!--                <el-row>
                                    <Orderlist :key="1" v-on:getlist="()=>{
                                    this.getlist()
                                    this.OrderlistPage = false
                                }"/>
                                </el-row>-->
                <el-row>
                    <Orderlist ref="orderlist" />
                </el-row>
            </el-dialog>

        </div>
    </div>
</template>
<script>
import Setpublish from './page/Setpublish'
import Grouplessoncopy from './page/Grouplessoncopy'
import Orderlist from "./page/Orderlist";

export default {
    components: {
        Grouplessoncopy,
        Setpublish,
        Orderlist,
    },
    data() {
        return {
            page_name: '团课管理',
            loading: true,      // 加载状态
            issearch: true,    // 搜索是否展示
            search: {},         // 搜索内容
            searchtime: null,    // 时间条件
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],      // 列表内容
            grouplessoncopyPage: false,   // 课表复制
            setPublishPage: false,   //批量上下架页面
            OrderlistPage: false,       // 预约信息
        }
    },
    // 创建
    created() {
        this.init()

    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断空
        is_empty(data) {
            return this.Tool.is_empty(data)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                store_name: '',
                publish_state: '',
                examine_status: "",
                date_ymd: '',
                title: '',
                lesson_name: '',
                user_nick: '',
                user_name: '',
                lesson_state: '',
                time_type: 'start_time',
            }
            this.searchtime = null
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "lessonschedule.group.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            if (this.searchtime !== null) {
                postdata.starttime = this.searchtime[0]
                postdata.endtime = this.searchtime[1]
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(good_uuid = '', title = '', operation = '') {

            let tip = ''
            let options = {}
            // 发布
            if (operation === 'upapply') {
                tip = '上架【' + title + '】？'
            }
            // 下架
            if (operation === 'withdraw') {
                tip = '<p style="text-align:center">取消发布【' + title + '】？</p><p style="color:red;text-align:center">（取消发布后，该节团课信息在用户端小程序不可见哦～）</p>'
                options["dangerouslyUseHTMLString"] = true;
            }
            // 删除
            if (operation === 'isdels') {
                tip = '删除【' + title + '】？'
            }

            // 查看原因
            if (operation === 'watch') {
                tip = good_uuid
            }

            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                .then(() => {
                    if (operation !== 'watch') {
                        this.isoperation(good_uuid, operation)
                    }
                })
                .catch(() => {
                });
        },
        // 操作
        isoperation(good_uuid = '', operation = '') {
            let postdata = {
                api_name: "lessonschedule.group." + operation,
                token: this.Tool.get_l_cache('token'),
                good_uuid,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 查看
        tosee(good_uuid = '', type) {
            if (good_uuid === '') {
                this.$router.push({ path: '/lessonschedule/groupteaching/edit' })
            } else {
                if (type === 'watch') {
                    this.$router.push({ path: '/lessonschedule/groupteaching/info', query: { good_uuid: good_uuid } })
                } else {
                    this.$router.push({ path: '/lessonschedule/groupteaching/edit', query: { good_uuid: good_uuid } })
                }
            }
        },
        // 取消课程
        cancel(info) {
            this.$prompt('请输入取消理由<span style="color:red">（若取消团课，则已报名的用户会全部退款哦～）</span>', '确定取消【' + info.title + '】课程?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                dangerouslyUseHTMLString: true
            }).then(({ value }) => {
                this.iscancel(info.good_uuid, value)
            }).catch(() => {
            });
        },
        iscancel(good_uuid, refund_remarks) {
            let postdata = {
                api_name: "lessonschedule.group.cancel",
                token: this.Tool.get_l_cache('token'),
                good_uuid,
                refund_remarks,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 打开订单
        openOrderlist(row) {
            let thi = this
            this.OrderlistPage = true
            setTimeout(() => {
                thi.$refs.orderlist.init(row.good_uuid)
            }, 100)
        },


    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-tag_colour-div {
    min-height: 200px;
}
</style>
