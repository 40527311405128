<template>
    <div v-loading="loading">
        <div style="height: 20px;"></div>
        <!--详情-->
        <el-row>
            <el-col :span="16">
                <el-form size="medium" :label-width="this.env.label_width">
                    <el-form-item label="选择门店">
                        <el-select v-model="form.store_uuid"
                                   filterable
                                   placeholder="请选择门店"
                                   style="width: 100%"
                                   @change="selectStore">
                            <el-option
                                    v-for="item in store_optionals"
                                    :key="item.store_uuid"
                                    :label="item.store_name"
                                    :value="item.store_uuid">
                                        <span style="float: left">{{ item.store_name }}
                                            <!--{{item.city}}{{item.county}}{{item.address}}-->
                                        </span>
                                <span style="float: right; color: #8492a6; font-size: 13px">
                                            【{{ item.company_name }}】
                                        </span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择教室">
                        <el-select v-model="form.classroom_uuid"
                                   filterable
                                   placeholder="请选择教室"
                                   style="width: 100%"
                                   @change="selectClassroom">
                            <el-option
                                    v-for="item in classroom_optionals"
                                    :key="item.classroom_uuid"
                                    :label="item.classroom_name"
                                    :value="item.classroom_uuid">
                                        <span style="float: left">{{ item.classroom_name }}
                                        </span>
                                <span style="float: right; color: #8492a6; font-size: 13px">
                                            【{{ item.area }}平/可容纳{{ item.capacity }}人】
                                        </span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择周期">
                        <el-date-picker
                                :disabled="this.Tool.is_empty(form.classroom_uuid)"
                                v-model="selectWeek"
                                type="week"
                                format="yyyy 第 WW 周"
                                value-format="yyyy-MM-dd"
                                :picker-options="pickerOptions"
                                @change="selectDate"
                                style="width: 100%"
                                placeholder="选择周">
                        </el-date-picker>
                        <span class="lessonsnum" v-if="lessonsnum > 0">共&nbsp;{{ lessonsnum }}&nbsp;节课</span>
                    </el-form-item>
                    <el-form-item label="上架时间">
                        <el-date-picker
                                v-model="form.publish_time"
                                type="datetime"
                                placeholder="选择上架时间(不选择即立即上架)"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm"
                                :picker-options="publishTimePickerOptions"
                                style="width: 100%">
                        </el-date-picker>
                        <span class="tip-error"> 仅上架使用，不选择即立即上架。 </span>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="16">
                <el-button v-if="is_auth('lessonschedule.group.setpublish')"
                           :disabled='lessonsnum === 0'
                           size="medium" type="success" @click="setpublish(1)">上架发布
                </el-button>

                <el-button v-if="is_auth('lessonschedule.group.setpublish')"
                           :disabled='lessonsnum === 0'
                           size="medium" type="warning" @click="setpublish(0)">下架撤回
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loading: false,             // 加载状态

            store_optionals: [],        // 可选门店
            classroom_optionals: [],    // 可选教室
            select_store_data: null,    // 选中的门店数据
            selectWeek: '',             // 选择的周
            lessonsnum: 0,               // 课程数量
            // 日期选择 配置
            pickerOptions: {
                firstDayOfWeek: 1,      // 从周一开始
            },
            // 上架时间选择 配置
            publishTimePickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            form: {
                store_uuid: null,           // 门店id
                classroom_uuid: null,       // 教室id
                datearea: [],               // 时间数组
                publish_time: null,         // 发布时间
            }

        }
    },
    // 创建
    created() {
        this.init()

    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.getStore()         // 获取可选门店
        },
        // 获取可选门店
        getStore() {
            let postdata = {
                api_name: "lessonschedule.lessonarrange.getstore",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.store_optionals = json.data.list
                } else {
                    this.store_optionals = []
                    this.Tool.errormes(json)
                }

            })
        },
        // 获取可选教室
        getClassroom(store_uuid) {
            let postdata = {
                api_name: "lessonschedule.lessonarrange.getclassroom",
                token: this.Tool.get_l_cache('token'),
                store_uuid
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                this.form.classroom_uuid = null     // 清空选择的的教室
                this.selectWeek = ''                // 清空选择的时间
                this.datearea = []                  // 情况时间数组
                if (json.code === 0) {
                    this.classroom_optionals = json.data.list
                } else {
                    this.classroom_optionals = []
                    if (json.code !== 10002) {
                        this.Tool.errormes(json)
                    }
                }
            })
        },
        // 获取时间段内课程数量
        getintimelessonsnum() {
            let postdata = {
                api_name: "lessonschedule.group.getintimelessonsnum",
                token: this.Tool.get_l_cache('token'),
                lesson_type: 1,     // 团课类型
            }
            Object.assign(postdata, this.form);
            // console.log('postdata', postdata)
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.lessonsnum = json.data.sum
                } else {
                    this.Tool.errormes(json)
                }
            })

        },
        // 选择门店
        selectStore(store_uuid) {
            console.log('store_uuid', store_uuid)
            this.getClassroom(store_uuid)   // 获取教室数据

            for (let item of this.store_optionals) {
                if (item.store_uuid === store_uuid) {
                    this.select_store_data = item
                    break
                }
            }
        },
        // 选择教室
        selectClassroom() {
            this.selectWeek = ''                // 清空选择的时间
            this.datearea = []                  // 情况时间数组
        },
        // 选择日期
        selectDate(selectWeek) {
            console.log('selectWeek', selectWeek)
            this.form.datearea = this.getDateArea(selectWeek)
            this.getintimelessonsnum()

        },
        // 获取选中的一周日期数据
        getDateArea(currentTime) {
            let currentDate = new Date(currentTime)
            let timesStamp = currentDate.getTime()
            let currenDay = currentDate.getDay()
            let dates = []
            for (let i = 0; i < 7; i++) {
                let date = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toLocaleDateString().replace(/\//g, '-')
                // let week = '无'
                // if (i === 0) week = '一'
                // if (i === 1) week = '二'
                // if (i === 2) week = '三'
                // if (i === 3) week = '四'
                // if (i === 4) week = '五'
                // if (i === 5) week = '六'
                // if (i === 6) week = '日'
                // dates.push({
                //     week,
                //     date,
                // });
                dates.push(date);
            }
            return dates
        },
        // 保存
        setpublish(publish = 'x') {
            let postdata = {
                api_name: "lessonschedule.group.setpublish",
                token: this.Tool.get_l_cache('token'),
                lesson_type: 1,     // 团课类型
                publish,
            }
            Object.assign(postdata, this.form);
            // console.log('postdata', postdata)
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            // this.getlist()    // 刷新列表(重新搜索)
                            this.$emit('getlist')
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lessonsnum {
    display: block;
    width: 100%;
    background-color: #f5f7fa;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    /*font-size: 12px;*/
    padding: 0 15px;
    box-sizing: border-box;
    /*text-align: center;*/
}

</style>
